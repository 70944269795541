import React from 'react'

function Archive() {
  return (
    <div>
          <section class="lading_read_vedio_sec">
      <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="archive_title">
                    <h3>Archive</h3>
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <nav class="lrvs_nav">
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button
                  class="nav-link active"
                  id="nav-read-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-read"
                  type="button"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                >
                  Read
                </button>
                <button
                  class="nav-link"
                  id="nav-video-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-video"
                  type="button"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >
                  Video
                </button>
              </div>
            </nav>
            <div class="lrvs_tab_panel">
              <div class="tab-content" id="nav-tabContent">
                <div
                  class="tab-pane fade active show"
                  id="nav-read"
                  role="tabpanel"
                  aria-labelledby="nav-read-tab"
                >
                  
                  <div class="read_contant_sec">
                    {/* <!--  --> */}

                    {/* <!-- <div class="container"> --> */}
                      {/* <!-- Slider main container --> */}
                      <div class="swiper slider1">
                        {/* <!-- Additional required wrapper --> */}
                        <div class="swiper-wrapper">
                          <div class="swiper-slide">
                            <div class="read_contant_sec_read_vedio">
                              <div class="read_contant_sec_read_image">
                                <img src="/images/read.png" alt="" />
                              </div>
                            </div>
                          </div>
                          <div class="swiper-slide">
                            <div class="read_contant_sec_read_vedio">
                              <div class="read_contant_sec_read_image">
                                <img src="/images/read.png" alt="" />
                              </div>
                            </div>
                          </div>
                          <div class="swiper-slide">
                            <div class="read_contant_sec_read_vedio">
                              <div class="read_contant_sec_read_image">
                                <img src="/images/read.png" alt="" />
                              </div>
                            </div>
                          </div>
                          <div class="swiper-slide">
                            <div class="read_contant_sec_read_vedio">
                              <div class="read_contant_sec_read_image">
                                <img src="/images/read.png" alt="" />
                              </div>
                            </div>
                          </div>
                          <div class="swiper-slide">
                            <div class="read_contant_sec_read_vedio">
                              <div class="read_contant_sec_read_image">
                                <img src="/images/read.png" alt="" />
                              </div>
                            </div>
                          </div>
                          <div class="swiper-slide">
                            <div class="read_contant_sec_read_vedio">
                              <div class="read_contant_sec_read_image">
                                <img src="/images/read.png" alt="" />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <!-- If we need navigation buttons --> */}
                        <div class="swiper-button-prev"></div>
                        <div class="swiper-button-next"></div>
                      </div>
                    {/* <!-- </div> --> */}

                    
                  </div>
                 
                </div>

                <div
                  class="tab-pane fade"
                  id="nav-video"
                  role="tabpanel"
                  aria-labelledby="nav-video-tab"
                >
                
                  <div class="read_contant_sec">
                    {/* <!--  --> */}

                    {/* <!-- <div class="container"> --> */}
                      {/* <!-- Slider main container --> */}
                      <div class="swiper slider2">
                        {/* <!-- Additional required wrapper --> */}
                        <div class="swiper-wrapper">
                          <div class="swiper-slide">
                            <div class="read_contant_sec_read_vedio">
                              <div class="read_contant_sec_read_image">
                                <img src="/images/read.png" alt="" />
                              </div>
                            </div>
                          </div>
                          <div class="swiper-slide">
                            <div class="read_contant_sec_read_vedio">
                              <div class="read_contant_sec_read_image">
                                <img src="/images/read.png" alt="" />
                              </div>
                            </div>
                          </div>
                          <div class="swiper-slide">
                            <div class="read_contant_sec_read_vedio">
                              <div class="read_contant_sec_read_image">
                                <img src="/images/read.png" alt="" />
                              </div>
                            </div>
                          </div>
                          <div class="swiper-slide">
                            <div class="read_contant_sec_read_vedio">
                              <div class="read_contant_sec_read_image">
                                <img src="/images/read.png" alt="" />
                              </div>
                            </div>
                          </div>
                          <div class="swiper-slide">
                            <div class="read_contant_sec_read_vedio">
                              <div class="read_contant_sec_read_image">
                                <img src="/images/read.png" alt="" />
                              </div>
                            </div>
                          </div>
                          <div class="swiper-slide">
                            <div class="read_contant_sec_read_vedio">
                              <div class="read_contant_sec_read_image">
                                <img src="/images/read.png" alt="" />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <!-- If we need navigation buttons --> */}
                        <div class="swiper-button-prev"></div>
                        <div class="swiper-button-next"></div>
                      </div>
                    {/* </div> */}

                    
                  </div>
                  
                  

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default Archive