import React from 'react'

function PrivacyPolicy() {
  return (
    <div>
      <section class="privcy_sec">
      <div class="container">
        <div class="row pb-5">
          <div class="col-md-12">
            <div class="privcy__tile">
              <h2>PRIVACY NOTICE</h2>
              <p>Last updated February 01, 2023</p>
            </div>
            <div class="pric__sect">
              <p>This privacy notice for Geeta Pariwar (' Company ', 'we', 'us', or 'our' ), describes how and why we might collect, store, use, and/or share ('process') your information when you use our services ('Services' ), such as when you:</p>
              <ul>
                <li>Visit our website at #### , or any website of ours that links to this privacy notice</li>
                <li>Download and use our mobile application (ShataKoti Hanuman Chalisa) , or any other application of ours that links to this privacy notice</li>
                <li>Engage with us in other related ways, including any sales, marketing, or events</li>
              </ul>
              <p>
                Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at info@learngeeta.com .
              </p>
            </div>
            <div class="pric__sect2">
              <h2>SUMMARY OF KEY POINTS</h2>
              <p>
                This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for. You can also click here to go directly to our table of contents.
              </p>
              <p>
                What personal information do we process? When you visit, use, or navigate our Services, we may process personal information depending on how you interact with Geeta Pariwar and the Services, the choices you make, and the products and features you use. Click  here to learn more.
              </p>
              <p> Do we process any sensitive personal information? We do not process sensitive personal information.</p>
              <p>
                Do we receive any information from third parties? We do not receive any information from third parties.
              </p>
              <p>
                How do we process your information? We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Click here to learn more.
              </p>
              <p>
                In what situations and with which parties do we share personal information? We may share information in specific situations and with specific third parties. Click here to learn more.
              </p>
              <p>
                How do we keep your information safe? We have organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Click here to learn more.
              </p>
              <p>
                What are your rights? Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Click here to learn more.
              </p>
              <p>
                How do you exercise your rights? The easiest way to exercise your rights is by filling out our data subject request form available here: https://rampratishtha.com , or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
              </p>
              <p>Want to learn more about what Geeta Pariwar does with any information we collect? Click  here to review the notice in full.</p>
              
              
            </div>
            <div class="pric__sect3">
              <h2>TABLE OF CONTENTS</h2>
              <ul>
                <li >
                  1. WHAT INFORMATION DO WE COLLECT?
                </li>
                <li >
                  2. HOW DO WE PROCESS YOUR INFORMATION?
                </li>
                <li >
                  3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?
                </li>
                <li >
                  4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?  
                </li>
                <li >
                  5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </li>
                <li >
                  6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                </li>

                <li >
                  7. HOW LONG DO WE KEEP YOUR INFORMATION?</li>
                <li >8. HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
                <li >9. DO WE COLLECT INFORMATION FROM MINORS?</li>
                <li >10. WHAT ARE YOUR PRIVACY RIGHTS?</li>
                <li >11. CONTROLS FOR DO-NOT-TRACK FEATURES</li>
                <li >12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
                <li >13. DO WE MAKE UPDATES TO THIS NOTICE?</li>
                <li >14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
                <li >15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</li>
                
              </ul>
              </div>
              <div class="pric__sect4">
                <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>

                <p>Personal information you disclose to us</p>
                <p>In Short: We collect personal information that you provide to us.</p>
                <p>We collect personal information that you voluntarily provide to us when you register on the Services,  express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>
                <p>Personal Information Provided by You. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
                <ul>
                  <li>names</li>
                  <li>email addresses</li>
                  <li>phone numbers</li>
                </ul>
                <p>Sensitive Information. We do not process sensitive information.</p>
                <p>Social Media Login Data. We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter, or other social media account. If you choose to register in this way, we will collect the information described in the section called 'HOW DO WE HANDLE YOUR SOCIAL LOGINS? ' below.</p>
                <p>Application Data. If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:</p>
                <ul>
                  <li>Geolocation Information. We may request access or permission to track location-based information from your mobile device, either continuously or while you are using our mobile application(s), to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device's settings.</li>
                  <li>Push Notifications. We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.</li>
                </ul>
                <p>This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.</p>
                <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>
                <h5>Information automatically collected</h5>
                <p>In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services</p>
                <p>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</p>
                <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
                <p>The information we collect includes:</p>
                <ul>
                  <li>Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps'), and hardware settings).</li>
                  <li>Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</li>
                  <li>Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</li>
                </ul>
                </div>
            
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default PrivacyPolicy