import React, { useEffect, useState } from 'react'
import axios from 'axios';
import swal from 'sweetalert';
import { NavLink,useParams } from 'react-router-dom';
import  Moment  from 'moment';

function Events(prop) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://production';
  const userinfo = localStorage.getItem('Userinfo');
  const token = localStorage.getItem('Token');
  const userjsoninfo = JSON.parse(userinfo);

  let [chantcount,setchantcount]= useState();
  const [statstt,setstatstt] = useState();
  const [statsmonthly,setmonthly] = useState();
  const [chanthistory,setchanthistory]=useState([]);
  const [targcount,settargcount] = useState();
  const [todaycount,settodaycount] = useState();
  const [appcounttotal,setappcounttotal] = useState();
  const [weaklyconunt,setweaklyconunt] = useState();
  const [selectedDate,setdatehistory] = useState();
  let params = useParams();
  const id= params.id;

  const appLang = prop.language;
  const events_and_groups = appLang.find(obj => obj.key === "events_and_groups").value;
  const total_chants = appLang.find(obj => obj.key === "total_chants").value;
  const your_chants = appLang.find(obj => obj.key === "your_chants").value;
  const current_week_progress = appLang.find(obj => obj.key === "current_week_progress").value;
  const current_month_progress = appLang.find(obj => obj.key === "current_month_progress").value;
  const today = appLang.find(obj => obj.key === "today").value;
  const submit = appLang.find(obj => obj.key === "submit").value;
  const submissions_list = appLang.find(obj => obj.key === "submissions_list").value;

  


  const dec = ()=>{
    if(chantcount > 0 ){
      chantcount = chantcount-1;
      setchantcount(chantcount);
    }
  }
  const inc = ()=>{
    if(chantcount< 1000){
      chantcount = chantcount+1;
      setchantcount(chantcount);
    }
    
  }

  const valuesetcansk = (e) =>{
    if(e.target.value < 1000){
      setchantcount(Number(e.target.value));
    }

  }
  const dateforhistory = ()=>{
    const today = new Date();
    let todayformated = Moment(today).format('MM-DD-YYYY');
    setdatehistory(todayformated);
  }
  function  getdateformated(data){
    let dateformated = Moment(data).format('DD-MM-YYYY');
        return dateformated
    }
  const getPreviousDate = () => {
    // const { selectedDate } = this.state

    const currentDayInMilli = new Date(selectedDate).getTime()
    const oneDay = 1000 * 60 *60 *24
    const previousDayInMilli = currentDayInMilli - oneDay
    const previousDate = new Date(previousDayInMilli)
    let todayformated = Moment(previousDate).format('MM-DD-YYYY');
    setdatehistory(todayformated);
   // setdatehistory(previousDate)
  }

  const getNextDate = () => {

    const currentDayInMilli = new Date(selectedDate).getTime()
    const oneDay = 1000 * 60 *60 *24
    const nextDayInMilli = currentDayInMilli + oneDay
    
    const nextDate = new Date(nextDayInMilli)
    let todayformated = Moment(nextDate).format('MM-DD-YYYY');
    setdatehistory(todayformated);
    // setdatehistory(nextDate)
  }
  const todaychantupdate = (e)=>{
    e.preventDefault();
    var idpayload = id;
    if(id=='my'){
      idpayload= ''
    }
    const formData = new FormData();
  formData.append('count',chantcount);
  formData.append('id',idpayload);
  const headers = {'content-type': 'multipart/form-data','Authorization': `Bearer ${token}`}   
  axios.post(`${API_ENDPOINT}/user/reads-update`,formData,{headers})
  .then((res)=>{
  
   if(res.status === 200){
    swal("Success", res.data.message, "success", {
      buttons: false,
      timer: 2000,
    })
    //setchantcount(0);
   }
   else{
   
   }
   getstats();
  })
  .catch((err)=>{
    swal("Failed", err.data.message, "error", {
      buttons: false,
      timer: 2000,
    })
  })
  }
  
 const getstats =() =>{
  const headers = {'content-type': 'multipart/form-data','Authorization': `Bearer ${token}`} 
  axios.get(`${API_ENDPOINT}/show-current-chants-count`,{headers})
  .then((resp)=>{
  
    setstatstt(resp.data.data.life_time_count);
    setappcounttotal(resp.data.data.total_app_count);
    setmonthly(resp.data.data.month_count);
    setweaklyconunt(resp.data.data.weekly_count);
    settodaycount(resp.data.data.today_count);
    settargcount(resp.data.data.target_count);

  })
  .catch((err)=>{
    console.log(err)
  })
}

const getvalueanddate = ()=>{
  const headers = {'Authorization': `Bearer ${token}`}
  axios.get(`${API_ENDPOINT}/user/reads-get`,{headers})
  .then((resp)=>{
   
    setchanthistory(resp.data.data);  

    if(id != 'my'){
      for(let i =0 ;i<resp.data.data.length;i++){
        if(resp.data.data[i].id === id){
          setchantcount(parseInt(resp.data.data[i].count));
          setdatehistory(resp.data.data[i].updated_at)
          
        }
      }
    }
    else{
      setchantcount(todaycount)
    }
  })
  .catch((e)=>{
   console.log(e)
  })
}
  useEffect(()=>{
     dateforhistory();
    getvalueanddate();
    getstats();
  },[id,todaycount])
  return (
    <section class="event_sec">
    <div class="container">
      <div class="row">
        <div class="col-md-5 m-auto">
          <div class="enent_card">
            <div class="enent_card__container">
             <NavLink to="/all-events">
             <div class="enent_card_header">
                <h3>{events_and_groups}</h3>
              </div>
              </NavLink> 
              <div class="enent_card_chants_box">
                <div class="enent_card_chants_box_main">
                  <div class="enent_card_chants_box_main_head">
                      <h3>{total_chants}</h3>
                  </div>
                  <div class="enent_card_chants_box_progress">
                    <div class="animated-progress progress-blue">
                      <span data-progress="50"></span>
                      <div class="enent_card_chants_box_progress_txt">{appcounttotal}</div>
                    </div>
                  </div>
                  <div class="enent_card_chants_box_dropdown">
                    <div class="dropdown">
                      <div class="select">
                        <span class='m-auto'>{userjsoninfo.name}</span>
                        <i class="fa fa-caret-right" aria-hidden="true"></i>
                      </div>
                      {/* <!-- <input type="hidden" name="gender"> --> */}
                      <ul class="dropdown-menu">
                        <li id="user1">User 1</li>
                        <li id="user2">User 2</li>
                        <li id="user3">User 3</li>
                        <li id="user4">User 4</li>
                        <li id="user5">User 5</li>
                        <li id="user6">User 6</li>
                        <li id="user7">User 7</li>
                      </ul>
                    </div>
                      {/* <!-- <span class="msg"></span> --> */}
                  </div>
                  <div class="enent_card_chants_box_main_head">
                      <h3>{your_chants}</h3>
                  </div>
                  <div class="enent_card_chants_box_progress">
                      <div class="animated-progress progress-blue">
                        <span data-progress="25"></span>
                        <div class="enent_card_chants_box_progress_txt">{total_chants}: {statstt}</div>
                      </div>
                    </div>
                    <div class="enent_card_chants_box_progress">
                      <div class="animated-progress progress-blue">
                        <span data-progress="45"></span>
                        <div class="enent_card_chants_box_progress_txt">{current_week_progress} {weaklyconunt}</div>
                      </div>
                    </div>
                    <div class="enent_card_chants_box_progress">
                      <div class="animated-progress progress-blue">
                        <span data-progress=""></span>
                        <div class="enent_card_chants_box_progress_txt">{current_month_progress} {statsmonthly}</div>
                      </div>
                    </div>
                    <div class="lrvs_tab_panel_date lrvs__enent__tab_panel_date">
                      <div class="lrvs_tab_panel_date_arrow_pre">
                        {/* <a  id='prevdate' onClick={getPreviousDate} ><img src="/images/arrow-left-circle.png" alt=""
                        /></a> */}
                      </div>
                      <div class="lrvs_tab_panel_date_contant">
                        {/* <div class="lrvs_tab_panel_date_contant_day">08</div>
                        <div class="lrvs_tab_panel_date_contant_month">July</div>
                        <div class="lrvs_tab_panel_date_contant_year">23</div> */}
                        {getdateformated(selectedDate)}
                      </div>
                      <div class="lrvs_tab_panel_date_arrow_next">
                        {/* <a  id='nextdate' onClick={getNextDate}>
                          <img src="/images/arrow-right-circle.png" alt=""
                        /></a> */}
                      </div>
                    </div>
                    <div class="enent_card_chants_box_progress">
                      <div class="animated-progress progress-blue">
                        <span data-progress="0"></span>
                        <div class="enent_card_chants_box_progress_txt">{today}: {todaycount}</div>
                      </div>
                    </div>
                    <form onSubmit={todaychantupdate}>
                    <div class="lrvs_counter_box">
                      <div class="counter" data-counter>
                          <div class="counter__btn counter__btn--minus" onClick={dec}>-</div>
                          <div class="counter__input-box">
                            <input class="counter__input" type="number" value={chantcount} min={0} max={1000} onChange={valuesetcansk} />
                          </div>
                          <div class="counter__btn counter__btn--plus" onClick={inc}>+</div>
                        </div>
                    </div>
                    <div class="lrvs_comp_button">
                      <button  type="submit">{submit}</button>
                    </div>
                    </form>
                    <div class="lrvs_comp_button ">
                    <NavLink to="/submission"> <button class="klpsds" >{submissions_list}</button></NavLink>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Events