import React from 'react'
import { NavLink } from 'react-router-dom'
function Bottomdefault() {
  return (
    <div> <footer>
    <div className="footer_container">
    <div className="footer_main">
      {/* <ul className="footer_main___open">
      <li className="footer_home">
        <a href="#" target="_blank" rel="noopener noreferrer"><img src="/images/home_icon.svg" alt="home icon" /></a>
      </li>
      <li className="footer_icon">
        <a href="#" target="_blank" rel="noopener noreferrer"><img src="/images/logo_gita.png" alt="logo gita" /></a>
      </li>
      <li className="footer_user">
        <a href="#" target="_blank" rel="noopener noreferrer"><img src="/images/user_icon.svg" alt="user icon" /></a>
      </li>
  </ul> */}
 <ul className="footer_main___close">
    <li className="footer_login_btn">
     <NavLink to="/login"> <input type="button" value="Log in"/></NavLink>
    </li>
</ul> 
    </div>
  </div>
  </footer></div>
  )
}

export default Bottomdefault