import React, { useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { useParams } from 'react-router-dom';

function Createevent(prop) {
  let params = useParams();
  const id= params.id;

  const navigate = useNavigate();

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://production';
    const token = localStorage.getItem('Token');
    const [eventtype,seteventtype]=useState([1,2,3,4,5,6,7,8,9]);
    const [eventplace,seteventplace]=useState([1,2,3,4,5,6,7,8,9]);
    const [state, setState] = useState({
        name: '',
        event_type: '',
        place_type: 17,
        start: '',
        end: '',
        phone_visible: 1,
        instraction: '',
        joing_links: '4',
        plateform: '4',
        organizer: '',
        targe_chants: '',
        participants: '',
        phone: '',
        public_event: 1,
        email: '',
        frequency: '',
        short_content: '',
        content: '',
        status: 1
    })

    const foreditdata = () =>{
      if(id != 'my')
      {
        const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${token}` }
        axios.get(`${API_ENDPOINT}/events/${id}`, { headers })
        .then((res) => {
          const data = res.data.data[0]
          console.log(data)
          let eventtypeselectedvalue ;

          for(var i=0;i<eventtype.length;i++){
            if(eventtype[i].name == data.event_type){
              eventtypeselectedvalue = eventtype[i].id
            }
          }
          
          setState({
            name: data.name,
            event_type: eventtypeselectedvalue,
            place_type: 17,
            start: data.start,
            end: data.end,
            phone_visible: data.phone_visible=="Yes"?(1):(0),
            instraction: data.instraction,
            joing_links: '4',
            plateform: '4',
            organizer: data.organizer,
            targe_chants: data.targe_chants,
            participants: data.participants,
            phone: data.phone,
            public_event: data.public_event=="Yes"?(1):(0),
            email: data.email,
            frequency: data.frequency,
            short_content: data.short_content,
            content: data.content,
            status: 1,
            id: id
          })
      })
      .catch((err) => {
        console.log(err)
      })
      }
    }
    const appLang = prop.language
    const go_back = appLang.find(obj => obj.key === "go_back").value;
    const events_and_groups = appLang.find(obj => obj.key === "events_and_groups").value;
    const add_group_or_event = appLang.find(obj => obj.key === "add_group_or_event").value;
    const event_name = appLang.find(obj => obj.key === "event_name").value;
    const list_as_public_event = appLang.find(obj => obj.key === "list_as_public_event").value;
    const event_type = appLang.find(obj => obj.key === "event_type").value;
    const frequency = appLang.find(obj => obj.key === "frequency").value;
    const select_start_date = appLang.find(obj => obj.key === "select_start_date").value;
    const select_end_date = appLang.find(obj => obj.key === "select_end_date").value;
    const event_description = appLang.find(obj => obj.key === "event_description").value;
    const expected_participants = appLang.find(obj => obj.key === "expected_participants").value;
    const organizer_name = appLang.find(obj => obj.key === "organizer_name").value;
    const organizer_phone = appLang.find(obj => obj.key === "organizer_phone").value;
    const organizer_email = appLang.find(obj => obj.key === "organizer_email").value;
    const make_phone_number_public = appLang.find(obj => obj.key === "make_phone_number_public").value;
    const save_and_continue = appLang.find(obj => obj.key === "save_and_continue").value;
    const yes = appLang.find(obj => obj.key === "yes").value;
    const no = appLang.find(obj => obj.key === "no").value;
    const chants_per_person = appLang.find(obj => obj.key === "chants_per_person").value;
    const select_event_type = appLang.find(obj => obj.key === "select_event_type").value;
    
 


    const editupdate = (e) =>{
      console.log("editsace")
      e.preventDefault();
    const data= state

  const headers = {'content-type': 'application/json','Authorization': `Bearer ${token}`}   
  axios.post(`${API_ENDPOINT}/events-update`,data,{headers})
  .then((res)=>{
   console.log(res);
   if(res.status === 200){
    swal("Success", res.data.message, "success", {
      buttons: false,
      timer: 2000,
    })
    navigate('/all-events')
   }
  })
  .catch((err)=>{
    swal("Failed", err.data.message, "error", {
      buttons: false,
      timer: 2000,
    })
  })
    }

    const update = event => {
        const target = event.currentTarget

        setState({
            ...state,
            [target.name]: target.type === 'checkbox' ? target.checked : target.value
        })
    }

    const getevnttype = () =>{
        const headers = {'content-type': 'application/json','Authorization': `Bearer ${token}`}   
        axios.get(`${API_ENDPOINT}/event-types`,{headers})
        .then((res)=>{
         seteventtype(res.data.data);
        })
        .catch((err)=>{
            console.log(err)
        })
    }


    const getevntplace = () =>{
        const headers = {'content-type': 'application/json','Authorization': `Bearer ${token}`}   
        axios.get(`${API_ENDPOINT}/event-place-types`,{headers})
        .then((res)=>{
         seteventplace(res.data.data);
        })
        .catch((err)=>{
            console.log(err)
        })
    }
const savedetails = (e) =>{

  e.preventDefault();
    const data= state;
    
  const headers = {'content-type': 'application/json','Authorization': `Bearer ${token}`}   
  axios.post(`${API_ENDPOINT}/events-store`,data,{headers})
  .then((res)=>{
   console.log(res);
   if(res.status === 200){
    swal("Success", res.data.message, "success", {
      buttons: false,
      timer: 2000,
    })
    setTimeout(() => {
        window.location.href="/locationadd/"+res.data.data.id;
      }, "2000");
   }
  })
  .catch((err)=>{
    swal("Failed", err.data.message, "error", {
      buttons: false,
      timer: 2000,
    })
  })
}
    useEffect(()=>{
        getevnttype();
        getevntplace();
        foreditdata();
    },[state.event_type])
  return (
    <section class="common_width">
    <section class="all_event_detals_sec">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="backbtn">
              <a href="#">
             
               <span>{go_back}</span>
            </a>
            </div>
          </div>
        </div>
      </div>
      <div class="container" >
       
        <div class="row">
          <div class="col-md-12">
            <form onSubmit={id == 'my'?(savedetails):(editupdate)}>
            <div class="add_events_main">
              <div class="add_events_main_tile">
                <NavLink to="/all-events"><a>{events_and_groups}</a></NavLink>
                <h3>{add_group_or_event}</h3>
              </div>
              <div class="add_events_main__input_txt">
                <label for="eventName"  class="form-label">{event_name}</label>
                <input type="text" class="form-control" name='name' value={state.name} onChange={update} id="eventName" required/>
                
              </div>
              <div class="add_events_main__input___select">
                <label>{list_as_public_event}</label>
                <select class="form-select" name="public_event" value={state.public_event}  aria-label="Default select example" onChange={(e)=>{update(e)}} required>   
                <option value={1}>{yes}</option>  
                <option value={0}>{no}</option> 
                </select>
              </div>
              {/* <div class="add_events_main__input_radio">
                <label for="" class="form-label">{list_as_public_event}</label>
                <div class="add___ents___check_box">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={state.public_event == 0?(true):(false)} />
                  <label class="form-check-label" for="flexRadioDefault1">
                    {yes}
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={state.public_event == 1?(true):(false)} />
                  <label class="form-check-label" for="flexRadioDefault2">
                    {no}
                  </label>
                </div>
              </div>
              </div> */}


              <div class="add_events_main__input___select">
                <label>{event_type}</label>
                <select class="form-select" name="event_type" value={state.event_type}  aria-label="Default select example" onChange={(e)=>{update(e)}} required>   
                <option >{select_event_type}</option>
                    {eventtype.map((data,index)=>{
                        return(
                        <option value={data.id} key={index}>{data.name} </option>
                        )
                    })}
                </select>
              </div>

              {/* <div class="add_events_main__input___select">
                <label>Place Type</label>
                <select class="form-select" name="place_type" aria-label="Default select example" onChange={(e)=>{update(e)}}>  
                <option >Select Event Type </option>  
                    {eventplace.map((data,index)=>{
                        return(
                        <option value={data.id} key={index}>{data.name} </option>
                        )
                    })}
                </select>
              </div> */}
              <div class="add_events_main__input___select">
                <label>{frequency}</label>
                <select class="form-select" name="frequency" value={state.frequency} aria-label="Default select example" onChange={(e)=>{update(e)}} required>
                  <option selected>---Select---</option>
                  <option value="One TIme">One TIme</option>
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                  <option value="Random">Random</option>
                </select>
              </div>
              <div class="add_events_main__input___dated">
                <div class="add_events_main__input___dated_start">
                  <label for="eventName" class="form-label">{select_start_date}</label>
                <input type="datetime-local" class="form-control" id="eventName" name='start' value={state.start} onChange={update} required/>
                </div>
                <div class="add_events_main__input___dated_end">
                  <label for="eventName" class="form-label">{select_end_date}</label>
                <input type="datetime-local" class="form-control" id="eventName" name='end'value={state.end} onChange={update} required/>
                </div>
              </div>

              <div class="add_events_main__input__textarea">
                <label for="exampleFormControlTextarea1" class="form-label">{event_description}</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" name='content' value={state.content} onChange={update} required></textarea>
              </div>
              {/* <div class="add_events_main__input__textarea">
                <label for="exampleFormControlTextarea1" class="form-label">Short Content</label>
                <input class="form-control" id="exampleFormControlTextarea1"  name='short_content' onChange={update}/>
              </div> */}
              <div class="add_events_main__input_txt">
                <label for="eventName" class="form-label">{expected_participants}</label>
                <input type="number" class="form-control" id="eventName" value={state.participants} name='participants' onChange={update}/>
              </div>
              <div class="add_events_main__input_txt">
                <label for="eventName" class="form-label">{chants_per_person}</label>
                <input type="number" class="form-control" id="eventName" name='targe_chants' value={state.targe_chants} onChange={update}/>
              </div>
              <div class="add_events_main__input_txt">
                <label for="eventName" class="form-label">{organizer_name}</label>
                <input type="text" class="form-control" id="eventName" name='organizer' value={state.organizer} onChange={update}/>
              </div>
              {/* <div class="add_events_main__input_txt">
                <label for="eventName" class="form-label">Instraction</label>
                <input type="text" class="form-control" id="eventName" name='instraction' onChange={update}/>
              </div> */}
              <div class="add_events_main__input_txt">
                <label for="eventName" class="form-label">{organizer_phone}</label>
                <input type="text" class="form-control" id="eventName"  name='phone' value={state.phone} onChange={update}/>
              </div>

              <div class="add_events_main__input___select">
                <label>{make_phone_number_public}</label>
                <select class="form-select" name="phone_visible" value={state.phone_visible} aria-label="Default select example" onChange={(e)=>{update(e)}} required>
                  
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </select>
              </div>
             

              <div class="add_events_main__input_txt">
                <label for="eventName" class="form-label">{organizer_email}</label>
                <input type="email" class="form-control" id="eventName"  name="email" value={state.email} onChange={update} required/>
              </div>

              <div class="add_events_main__button_main">
              <button type="submit">{save_and_continue}</button>
              </div>

            </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </section>
  )
}

export default Createevent