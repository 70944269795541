import React from 'react'

function OldCounts() {
  return (
    <section class="event_sec">
    <div class="container">
      <div class="row">
        <div class="col-md-5 m-auto">
          <div class="enent_card">
            <div class="enent_card__container">
              <div class="enent_card_chants_box">
                <div class="enent_card_chants_box_main old_card_chants_box_main">
                  <div class="enent_card_chants_box_main_head">
                      <h3>Old Counts </h3>
                      <p>(15 June to 15 July 2023)</p>
                  </div>

                  <div class="enent_card_chants_box_main_sub_head">
                      <p>Enter the total counts of Bhagavad Gita from <b> 15 June</b> to <b>15 July 2023</b></p>
                  </div>
                  <div class="enent_card_chants_box_main_sub_dis_count">
                      <p>0</p>
                  </div>
                  <div class="lrvs_comp_button">
                      <button>Update</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default OldCounts