import React from 'react'
import { NavLink } from 'react-router-dom'

function Bottom() {
  return (
    <footer>
    <div className="footer_container">
    <div className="footer_main">
      <ul className="footer_main___open">
      <li className="footer_home">
       <NavLink to="/"> <img src="/images/home_icon.svg" alt="home icon" /></NavLink>
      </li>
      <li className="footer_icon">
      <NavLink to="/events/my"><img src="/images/shanku.png" alt="logo gita" /></NavLink>  
      </li>
      <li className="footer_user">
      <NavLink to="/landingRead"> <img src="/images/open-book.png" alt="user icon" /> </NavLink> 
      </li>
  </ul>
  {/* <!-- <ul className="footer_main___close">
    <li className="footer_login_btn">
      <input type="button" value="Log in">
    </li>
</ul> --> */}
    </div>
  </div>
  </footer>
  )
}

export default Bottom