import React, { useEffect } from 'react'
import { useState } from 'react';
import { json, useParams } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { getValue } from '@testing-library/user-event/dist/utils';
import { useNavigate } from 'react-router-dom';

function Updateeventhistory(prop) {
  const appLang = prop.language
  const submit = appLang.find(obj => obj.key === "submit").value;

  const [fileurl,setfilurl]=useState();
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://production';
    const token = localStorage.getItem('Token');
  const navigate = useNavigate();
    let [chantcount,setchantcount]= useState(0);

    const parama = useParams();

    const historyid= parama.id;
    const count =parama.count;


    const dec = ()=>{
        if(chantcount > 0){
          chantcount = chantcount-1;
          setchantcount(chantcount);
        }
      }
      const inc = ()=>{
        if(chantcount < 10000){
          chantcount = chantcount+1;
          setchantcount(chantcount);
        }
       
      }
      const getfileurl = (e) =>{
        setfilurl(e.target.files[0])
        
      }

      const todaychantupdate = (e)=>{
        e.preventDefault();
        const formData = new FormData();
        formData.append('count',chantcount);
        formData.append('id',historyid);
        const headers = {'content-type': 'multipart/form-data','Authorization': `Bearer ${token}`}   
        axios.post(`${API_ENDPOINT}/events-history/update`,formData,{headers})
        .then((res)=>{
          console.log(res)
         if(res.status === 200){
          swal("Success", res.data.message, "success", {
            buttons: false,
            timer: 2000,
          })
          navigate('/all-events')
         }
     
        })
        .catch((err)=>{
          swal("Failed", err.data.message, "error", {
            buttons: false,
            timer: 2000,
          })
        })
      }

      const updateimage = ()=>{
        const formData = new FormData();
       formData.append('id',historyid);
       formData.append('count',chantcount);
       formData.append('photo',fileurl);
       const headers = {'content-type': 'multipart/form-data','Authorization': `Bearer ${token}`}   
       axios.post(`${API_ENDPOINT}/events-history/update`,formData,{headers})
       .then((res)=>{
        if(res.status === 200){
         swal("Success", res.data.message, "success", {
           buttons: false,
           timer: 2000,
         })
         navigate('/all-events')
        }
       
       })
       .catch((err)=>{
         swal("Failed", err.data.message, "error", {
           buttons: false,
           timer: 2000,
         })
       })
      }

  const getValuels =(e)=>{
    if(e.target.value<10000){
      setchantcount(Number(e.target.value));
    }
    
      }
      useEffect(()=>{
        setchantcount(JSON.parse(count))
      },[])
  return (
    <section className="event_sec">
      <div class="modal fade" id="updateimageperticular" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="share" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Upload image</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body text-center">
        <form >
        <input type='file' onChange={getfileurl} required/>
        <br/>
        <buton type="submit" class="uplodsds" onClick={updateimage}>Submit</buton>
        </form>
      </div>
    </div>
  </div>
</div>
    <div className="container">
      <div className="row">
        <div className="col-md-5 m-auto">
          <div className="enent_card">
            <div className="enent_card__container">
           
              <div className="enent_card_chants_box">
                <form onSubmit={todaychantupdate}>
                <div className="enent_card_chants_box_main">
                    <div className="lrvs_counter_box">
                      <div className="counter" data-counter>
                          <div className="counter__btn counter__btn--minus" onClick={dec}>-</div>
                          <div className="counter__input-box">
                            <input className="counter__input" type="number" value={chantcount} min={0} max={10000} onChange={getValuels} required/>
                          </div>
                          <div className="counter__btn counter__btn--plus" onClick={inc}>+</div>
                        </div>
                    </div>
                    <div className="lrvs_comp_button">
                      <button type='submit'>{submit}</button>
                    </div>
                    
                </div>
                </form>
                <div class="aed_event_Body_subc_btn_sec">
                <button data-bs-toggle="modal" data-bs-target="#updateimageperticular">Event Image update</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Updateeventhistory