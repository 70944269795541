import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import useDocumentTitle from './useDocumentTitle';

function About(prop) {

  useDocumentTitle('Home 👻')
  const appLang = prop.language
  const privacy_policy = appLang.find(obj => obj.key === "privacy_policy").value;
  const available_on = appLang.find(obj => obj.key === "available_on").value;

  const [dataContent, setdata] = useState("Loading.....");
  const [data, setdataall] = useState();
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://production';
  const getapidata = ()=>{
    const appLang = JSON.parse(localStorage.getItem('app-lang'))

    axios.get(`${API_ENDPOINT}/pages/20`)

      .then((res) => {
        for (var i = 0; i < res.data.data[0].translations.length; i++) {
          if (res.data.data[0].translations[i].lang === appLang[0].code) {
            setdata(res.data.data[0].translations[i].content);
            setdataall(res.data.data[0].translations[i])
          }
        }
      })
  }
  useEffect(()=>{
    getapidata(); 
  },[dataContent])

  

  return (

    <div>

      
      <section className="width___fix">
      
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="about_content">
                <div className="about_content_title">
                  <h2>ABOUT GIEO GITA</h2>
                </div>
                <div className="about_content_pra">
                 <div dangerouslySetInnerHTML={{ __html: dataContent }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      
      <section className="mobile_app">
        <div className="mobile_app_webview">
          <div className="mobile_app_webview__head">{available_on}</div>
          <div className="app_store_logos" >
              <a className="app_store_logos__anch" href="#">
                  <img src="/images/andr.png" alt="" className="app_store_logos__anch_img_1"/>
              </a>
              <a className="app_store_logos__anch" href="#">
                  <img src="/images/app_store.png" alt="" className="app_store_logos__anch_img_1"/>
              </a>
          </div>
          <div className="privcy_pg">
           <NavLink to="/privacy-policy">
              {privacy_policy}</NavLink>
          </div>
      </div>
      </section>
      </section>
    </div>
  )
}

export default About