import React from 'react'
import { useNavigate ,NavLink, redirect } from 'react-router-dom'

function Menus(prop) {
    const appLang = prop.language
    const my_pledge = appLang.find(obj => obj.key === "my_pledge").value;
    const select_language = appLang.find(obj => obj.key === "select_language").value;
    const help = appLang.find(obj => obj.key === "help").value;
    const events_and_groups = appLang.find(obj => obj.key === "events_and_groups").value;
    const promotional_material = appLang.find(obj => obj.key === "promotional_material").value;
   const update_profile = appLang.find(obj => obj.key === "update_profile").value;
    const log_out = appLang.find(obj => obj.key === "log_out").value;
  

    const navigate = useNavigate();
    const logout =()=> {
          localStorage.removeItem("Token"); 
          localStorage.removeItem("app-lang"); 
          localStorage.removeItem("Userinfo");
          localStorage.removeItem("app-lang");
        //   localStorage.removeItem("app-lang");
          window.location.href ='/'
    }
    // const redirect = (e) => {
    //    window.location.href='/languageList'
    //    window.location.reload(true);
    // }
    return (
        <section class="event_sec">
            <div class="container">
                <div class="row">
                    <div class="col-md-5 m-auto">
                        <div class="enent_card">
                            <div class="enent_card__container">
                                <ul className='menuul text-center'>
                                    <li>
                                    <NavLink to="/mypledge"><a>{my_pledge}</a></NavLink> 

                                    </li>
                                    <li>
                                    <NavLink to="/languageList"><a >{select_language}</a></NavLink> 
                                    </li>
                                    <li>
                                    <NavLink to="/help">   <a>{help}</a></NavLink>
                                        
                                    </li><li>
                                    <NavLink to="/all-events"><a>{events_and_groups}</a></NavLink>
                                        
                                    </li>
                                    <li>
                                        <a>{promotional_material}</a>
                                        
                                    </li>
                                    <li>
                                    <NavLink to="/mypledgeForm"><a>{update_profile}</a></NavLink> 

                                    </li>
                                    <li>
                                    <a onClick={logout}>{log_out}</a>
                                        
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Menus