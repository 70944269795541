import React from 'react'

function Help(prop) {
  const appLang = prop.language
  const help = appLang.find(obj => obj.key === "help").value;
  const version_information = appLang.find(obj => obj.key === "version_information").value;
  const os = appLang.find(obj => obj.key === "os").value;
  const host = appLang.find(obj => obj.key === "host").value;
  const android = appLang.find(obj => obj.key === "android").value;
  const os_version = appLang.find(obj => obj.key === "os_version").value;
  const app_version = appLang.find(obj => obj.key === "app_version").value;
  const setting_version = appLang.find(obj => obj.key === "setting_version").value;
  const page_version = appLang.find(obj => obj.key === "page_version").value;
  const report_issues_or_feedback = appLang.find(obj => obj.key === "report_issues_or_feedback").value;



  return (
    <section class="event_sec">
      <div class="container">
        <div class="row">
          <div class="col-md-5 m-auto">
            <div class="enent_card">
              <div class="enent_card__container">
                <div class="enent_card_chants_box">
                  <div class="enent_card_chants_box_main">
                    <div class="enent_card_chants_box_main_head">
                        <h3>{help}</h3>
                    </div>

                    
                    <div class="pledge_card_chants_heda pledge_card_chants_heda___help">
                        <p>{version_information}</p>
                    </div>
                    
                    <div class="pledge_card_chants_table pledge_card_chants_table____help">
                        <table>
                            <tr>
                                <td><b>{host}</b></td>
                                <td>{host}</td>
                            </tr>
                            <tr>
                                <td><b>{os}</b></td>
                                <td>{android}</td>
                            </tr>
                            <tr>
                                <td><b>{os_version}</b></td>
                                <td>22</td>
                            </tr>
                            <tr>
                                <td><b>{app_version}</b></td>
                                <td>1.2220.120</td>
                            </tr>
                            <tr>
                              <td><b>{app_version}</b></td>
                              <td>1.20.03</td>
                          </tr>
                          <tr>
                            <td><b>{page_version}</b></td>
                            <td>web/p.12.3.4.5</td>
                        </tr>
                        </table>
                    </div>
                    <div class="lrvs_comp_button">
                        <button>{report_issues_or_feedback}</button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Help