import React from 'react';
import { NavLink } from "react-router-dom";

function Create() {
  return (
    <section class="login_sec">
      <div class="container">
        <div class="row">
          <div class="col-md-5 m-auto">
            <div class="login_card">
              <div class="lc_image">
                <img src="./images/logo_gita.png" alt="" />
              </div>
              <div class="lc_form">
                <h2>Create an Account</h2>
                <form method="" action="">
                  <div class="form_input">
                    <div class="form-group form_group_email">
                      <input
                        type="name"
                        class="form-control"
                        name="name"
                        placeholder="Your Name"
                        value=""
                      />
                    </div>
                    <div class="form-group form_group_email">
                      <input
                        type="number"
                        class="form-control"
                        name="number"
                        placeholder="Contact Number"
                        value=""
                      />
                    </div>
                    <div class="form-group form_group_email">
                      <input
                        type="email"
                        class="form-control"
                        name="email"
                        placeholder="Email Id (Optional)"
                        value=""
                      />
                    </div>
                    <div class="form-group form_group_pwd">
                      <input
                        id="password-field"
                        type="password"
                        class="form-control"
                        name="password"
                        placeholder="Set Password"
                      />
                      <span
                        toggle="#password-field"
                        class="fa fa-fw fa-eye field-icon toggle-password"
                      ></span>
                    </div>

                    <div class="form-group form_group_btn">
                      <button><p>Register Now</p>
                        <span class="right__icon"><i class="fas fa-arrow-right"></i></span></button>

                    </div>
                  </div>
                </form>
                <h3 class="lc_or">OR</h3>
                <div class="lc__icon_box">
                  <div class="lc_img_icon_txt">
                    <p>Register with</p>
                  </div>
                  <div class="lc_img_icon_cont_box">
                    <div class="lc_img_icon_cont">
                      <a href="#"><img src="./images/facebook.png" alt="" /></a>
                    </div>
                    <div class="lc_img_icon_cont">
                      <a href="#"><img src="./images/insta.png" alt="" /></a>
                    </div>
                    <div class="lc_img_icon_cont">
                      <a href="#"><img src="./images/linkedin.png" alt="" /></a>
                    </div>
                    <div class="lc_img_icon_cont">
                      <a href="#"><img src="./images/twite.png" alt="" /></a>
                    </div>
                  </div>
                </div>
                <div class="lc___ragister">
                  <p>Already have an account <NavLink to="/" exact><a rel="noopener noreferrer">Login Here </a></NavLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Create