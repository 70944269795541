import React, { useEffect, useState } from 'react'
import axios from 'axios';
import swal from 'sweetalert';
import { useNavigate  } from 'react-router-dom';

function LanguageList(props) {
    const prop = props
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://production';
    const token = localStorage.getItem('Token');
    const navigate = useNavigate();
    const [languages, setlang] = useState([]);
    const getlanguages = () => {
        axios.get(`${API_ENDPOINT}/show-all-language`)
            .then((resp) => {
               
                setlang(resp.data.data);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // let refreshtoken = false
    // function refreshpage(){
    //     console.log("fnrun")
    //     if(refreshtoken == false){
    //         window.location.reload(true);
    //         refreshtoken = true;
    //         console.log("pagerefreshed")
    //     }
    // }

    function setlangforapp(data,name,id){
        localStorage.setItem('app-lang',data);
        const mypamdf = {
            "lang":id
        }
        const headers = { 'content-type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }
        axios.post(`${API_ENDPOINT}/change-default-language`,mypamdf ,{ headers })
        .then((res)=>{
            console.log(res);
        })
            axios.get(`${API_ENDPOINT}/translation-get/${data}`, { headers })
              .then((res) => {
                localStorage.setItem('app-lang',JSON.stringify(res.data.data));
                swal("Success", "Set Default Language To "+name, "success", {
                    buttons: false,
                    timer: 3000,
                  })
                  setTimeout(function() {
                    navigate('/')
                     prop.handler();
                }, 3000)
              });   
    }

    useEffect(() => {
        getlanguages();
        // refreshpage();
      
    },[])


    return (
        <section class="event_sec">
            <div class="container">
                <div class="row">
                    <div class="col-md-5 m-auto">
                        <div class="enent_card">
                            <div class="enent_card__container">
                            {/* <div style={{margin: "auto",textAlign: "center"}} id="google_translate_element"></div>   */}
                                <ul className='menuul text-center'>
                                    {languages.map((data) => {
                                        return (
                                            <li key={data.id} onClick={()=>setlangforapp(data.code,data.name,data.id)}>
                                                <a >{data.name}</a>
                                            </li>
                                        )
                                    })}


                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LanguageList