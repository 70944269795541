import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

function Headerbefore() {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://production';
    const redirect = (e) => {
        window.location.href='/languageList'
        window.location.reload(true);
     }
     useEffect(()=>{
     
     })
  return (
    <div>
          <header>
      <div className="nav_menu_main">
        <div className="nmm_logo">
        <NavLink to="/"><img src="/images/logo_gita.png" alt="" /></NavLink> 
        </div>
        <div className="nmm_header">
          <h3>GIEO GITA</h3>
        </div>
        <div className="nmm_user">
      
        <a onClick={redirect} > <img src="/images/languages.png" alt="" /></a>
        </div>
      </div>
    </header>
    </div>
  )
}

export default Headerbefore