import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Document, Page, pdfjs } from 'react-pdf';

const token = localStorage.getItem('Token');

function LandingRead(prop) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://production';
  pdfjs.GlobalWorkerOptions.workerSrc =  `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [pdf, setpdf] = useState();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [response, setresponse] = useState([]);
  const [appsettingdata,setappsetingdata] = useState([]);
  const [languages, setlang] = useState([]);
  const [refreshiframe, setrefreshiframe] = useState(0);
  const [iframeurl, setiframeurl] = useState("https://www.youtube.com/embed/l1pXzZIz0Jg?si=GyIsiA3zROAWe--l");
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

    
  const appLang = prop.language
  const read = appLang.find(obj => obj.key === "read").value;
  const video = appLang.find(obj => obj.key === "video").value;
  const select_language = appLang.find(obj => obj.key === "select_language").value;

  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(
      pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
    );

  const getdata = () => {
    const headers = { 'content-type': 'multipart/form-data', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${token}` }
    axios.get(`${API_ENDPOINT}/pages/1`, { headers })
      .then((res) => {
      //   localStorage.setItem('pdfurl', res.data.data[0].translations[0].file_short_content);
      // const pdfurlfinal = localStorage.getItem('pdfurl');
      console.log(res.data.data[0])
       console.log(res.data.data[0].translations[0].file_short_content)
        setresponse(res.data.data[0].translations);
        setpdf(res.data.data[0].translations[0].file_short_content);
      })
  }

const getappseting = () =>{
  axios.get(`${API_ENDPOINT}/app-settings`,)
    .then((res) => {
      console.log(res);
      const type = 'app_setting_video_links';
      var finder = res.data.data.filter(el => el.type === type);
      setappsetingdata( JSON.parse(finder[0].value))
      const bijn= JSON.parse(finder[0].value);
      setiframeurl('https://www.youtube.com/embed/'+bijn[0])
    })
}

  const setpdflanguage = (e)=>{
  
  // const headers = { 'content-type': 'multipart/form-data', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${token}` }
    axios.get(`${API_ENDPOINT}/pages/1`)
      .then((res) => {
      console.log(res.data.data[0].translations);
       console.log(res.data.data[0].translations[0].file_short_content);
        setresponse(res.data.data[0].translations);
        const found = res.data.data[0].translations.find(obj => {return obj.lang === e.target.value;});
         console.log(found);
        setpdf(found.file_short_content);
      })
      setrefreshiframe(iframeurl+1);

  }
  const getlanguages = () => {
      axios.get(`${API_ENDPOINT}/show-all-language`)
          .then((resp) => {
              setlang(resp.data.data);
          })
          .catch((err) => {
              console.log(err)
          })
  }
  const getdatavideo = () => {
    const headers = { 'content-type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }
    axios.get(`${API_ENDPOINT}/pages/25`, { headers })
      .then((res) => {
      })
  }
  const youtubeurl = (url) =>{
    setiframeurl('https://www.youtube.com/embed/'+url)
  }

  useEffect(() => {
   // pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    getdata();
    getlanguages();
    getappseting();
    getdatavideo();
  }, [])

  return (
    <section class="lading_read_vedio_sec">
      <section class='width___fix mt-5'>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <nav class="lrvs_nav">
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button
                  class="nav-link active"
                  id="nav-read-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-read"
                  type="button"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                >
                  {read}
                </button>
                <button
                  class="nav-link"
                  id="nav-video-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-video"
                  type="button"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >
                  {video}
                </button>
              </div>
            </nav>
            <div class="lrvs_tab_panel">
              <div class="tab-content" id="nav-tabContent">
                <div
                  class="tab-pane fade active show"
                  id="nav-read"
                  role="tabpanel"
                  aria-labelledby="nav-read-tab"
                >
                  <div class="lrvs_tab_panel_date">
                    {/* <div class="lrvs_tab_panel_date_arrow_pre">
                      <a href="#"
                      ><img src="/images/arrow-left-circle.png" alt=""
                        /></a>
                    </div> */}
                    
                  <select class="lrvs_tab_panel_date_contant form-select" aria-label="Default select example" onChange={(e)=>setpdflanguage(e)}>
                    <option selected value={0}>{select_language}</option>
                    {languages.map((data) => {
                                        return (
                                          <option value={data.code} key={data.id}>{data.name}</option> 
                                        )
                                    })}
                   
                  </select>
                                    
                    {/* <div class="lrvs_tab_panel_date_arrow_next">
                      <a href="#">
                        <img src="/images/arrow-right-circle.png" alt=""
                        /></a>
                    </div> */}
                  </div>
                  <div class="read_contant_sec">
                    {/* <!--  --> */}
                    {/* <!-- <div class="container"> --> */}
                    {/* <!-- Slider main container --> */}
                    <div class="swiper slider1">
                      {/* <!-- Additional required wrapper --> */}
                      <div class="swiper-wrapper">
                        <div class="swiper-slide mt-3">
                          <div class="read_contant_sec_read_vedio">
                            <div class="read_contant_sec_read_image">
                              <Document
                            //  file="/images/Single-Page-Setting-English.pdf"
                            
                              file={pdf}
                              onLoadSuccess={onDocumentLoadSuccess}
                              >
                                <Page renderTextLayer={false} pageNumber={pageNumber} />
                              </Document>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- If we need navigation buttons --> */}
                      <p class='mt-2'>Page {pageNumber} of {numPages}</p>
                      <div class="d-flex">
                      <div class="swiper-button-prev m-2  " onClick={goToPrevPage}></div>
                      <div class="swiper-button-next  m-2" onClick={goToNextPage}></div>
                      </div>
                      
                    </div>
                    {/* <!-- </div> --> */}


                  </div>
                  {/* <div class="lrvs_comp_button lrvs_comp_button___landing">
                    <button>Complete</button>
                  </div>
                  <div class="lrvs___your_chants_box">
                    <div class="lrvs___your_chants_title">Your Chants</div>
                    <div class="lrvs___your_chants_cards">
                      <div class="lrvs___your_chants_cards_todays">
                        <div class="lrvs___your_chants_cards_title">Today</div>
                        <div class="lrvs___your_chants_cards_count">12</div>
                      </div>
                      <div class="lrvs___your_chants_cards_week">
                        <div class="lrvs___your_chants_cards_title">This Week</div>
                        <div class="lrvs___your_chants_cards_count">12</div>
                      </div>
                      <div class="lrvs___your_chants_cards_month">
                        <div class="lrvs___your_chants_cards_title">This Month</div>
                        <div class="lrvs___your_chants_cards_count">12</div>
                      </div>
                    </div>
                  </div> */}
                </div>

                <div
                  class="tab-pane fade"
                  id="nav-video"
                  role="tabpanel"
                  aria-labelledby="nav-video-tab"
                >
                  <div class="embed-responsive embed-responsive-16by9">
            <iframe width="280" height="157" src={iframeurl} frameborder="0" allowfullscreen></iframe>
          </div>
          <div class="thumbnails">
            {appsettingdata.map((data,index)=>{return(
              
              <div class="thumbnail text-center">
              <div className='playbtn'>
                <img src='/images/play.png' onClick={()=>{youtubeurl(data)}}/>
              </div>
              <img src={`https://img.youtube.com/vi/${data}/0.jpg`} class="m-auto w-100"/>
            </div>
            )})}
          </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>
    </section>
  )
}

export default LandingRead