import React from 'react'
import AppleLogin from 'react-apple-login'

function AppleSignInButton () {
    const handleAppleLoginSuccess = (user) => {
        // Handle successful login with the received user data
        console.log('Apple Login Success:', user);
      };
    
      const handleAppleLoginFailure = (error) => {
        // Handle errors during the login process
        console.error('Apple Login Error:', error);
      };
    
      return (
        <div>
       <AppleLogin
        clientId="org.gieogita.18shlokarecitations"
         redirectURI="https://18shlokarecitation.gieogita.org/login" 
         onSuccess={handleAppleLoginSuccess}
        onError={handleAppleLoginFailure}
         />
        </div>
      );
}

export default AppleSignInButton 