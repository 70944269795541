import axios from 'axios';
import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';

function MyPleadgeForm() {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://production';
  const userinfo = localStorage.getItem('Userinfo');
  const token = localStorage.getItem('Token');
  const userjsoninfo = JSON.parse(userinfo);
  const [imgpro,setimgpro]=useState(null);
  const [fileurl,setfilurl]=useState();
  const  navigate = useNavigate();
  const [name,setname]=useState("Loading...");
  const [email,setemail]=useState("Loading...");
  const [phone,setphone]=useState("Loading...");

const getname = (e)=>{
  setname(e.target.value);
}

const getemail = (e)=>{
  setemail(e.target.value);
}
const getphone = (e)=>{
  setphone(e.target.value);
}

const usrinfo = ()=>{
  const headers = {'Authorization': `Bearer ${token}`}
  axios.get(`${API_ENDPOINT}/profile-details`,{headers})
  .then((resp)=>{
    
    if(resp.data.data[0].avatar_original != null){
      setimgpro(resp.data.data[0].avatar_original);
    }
    setname(resp.data.data[0].name);
    if(resp.data.data[0].email.includes('@')){
      setemail(resp.data.data[0].email);
    }
    else{
      setemail('');
    }
    setphone(resp.data.data[0].phone);
  })
  .catch((e)=>{
console.log(e)
  })
}

useEffect(()=>{
  usrinfo();
},[])
const updateprofile = (e) => {
  const formData = new FormData();
  formData.append('email',email);
  formData.append('name',name);
  const headers = {'content-type': 'multipart/form-data','Authorization': `Bearer ${token}`}   
  axios.post(`${API_ENDPOINT}/profile-update`,formData,{headers})
  .then((res)=>{
  
   if(res.status === 200){
    swal("Success", res.data.message, "success", {
      buttons: false,
      timer: 2000,
    })
    usrinfo();
  }
  })
  .catch((err)=>{
    swal("Failed", err.data.message, "error", {
      buttons: false,
      timer: 2000,
    })
  })

}
const getfileurl = (e) =>{
  setfilurl(e.target.files[0]); 
}
const updateimage = ()=>{
  const formData = new FormData();
 formData.append('avatar',fileurl);
 const headers = {'content-type': 'multipart/form-data','Authorization': `Bearer ${token}`}   
 axios.post(`${API_ENDPOINT}/profile-pic-update`,formData,{headers})
 .then((res)=>{
  if(res.status === 200){
   swal("Success", res.data.message, "success", {
     buttons: false,
     timer: 2000,
   })
  }
  window.location.href="/mypledgeForm"
 })
 .catch((err)=>{
   swal("Failed", err.data.message, "error", {
     buttons: false,
     timer: 2000,
   })
 })

}

  return (
    <section class="event_sec">
      <div class="modal fade" id="updateimage" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="share" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Update Profile image</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body text-center">
        <form >
        <input type='file' onChange={getfileurl} required/>
        <br/>
        <buton type="submit" class="uplodsds" onClick={updateimage}>Submit</buton>
        </form>
      </div>
    </div>
  </div>
</div>
    <div class="container">
      <div class="row">
        <div class="col-md-5 m-auto">
          <div class="enent_card">
            <div class="enent_card__container">
              <div class="enent_card_chants_box">
                <div class="enent_card_chants_box_main pleadge___card_chants_box_main">
                  <div class="pleadge___card_chants_box_name_main">
                    <div class="pleadge___card_chant_icon_name_box">
                      <div class="pleadge___card_chant_icon_name_img">
                        {imgpro == null?(<> <img src="/images/user.svg"alt="" /></>):(<><img src={imgpro} alt="" /></>)}
                       
                      </div>
                      <div class="pleadge___card_chant_icon_name_text">{name}</div>
                    </div>
                    <div class="pleadge___card_chant_icon_edit">
                     <a data-bs-toggle="modal" data-bs-target="#updateimage"> <img src="/images/edit.svg"alt="" /></a>
                    </div>
                  </div>
                  {/* <!-- <div class="pleadge___card_chants_box_name_main2"> --> */}
                    {/* <div class="pleadge___card_chant_icon_name_box2">
                      <div class="pleadge___card_chant_icon_name_img2">
                        <img src="/images/user-plus.svg"alt="" />
                      </div>
                      <div class="pleadge___card_chant_icon_name_text2">Add Family Member</div>
                    </div> */}
                  {/* <!-- </div> --> */}

                  <div class="pledge_card_chants_box_main_sub_head">
                      <p>You can add kids and elders who can not operate separate account</p>
                  </div>
                  <form >
                    <div class="form_input">
                  <div class="form-group form_group_email pledge__form_group_email">
                    <label for="text">Name</label>
                    <input
                    required
                      type="text"
                      class="form-control"
                      name="text"
                      
                      value={name}
                      onChange={getname}
                    />
                  </div>
                  <div class="form-group form_group_email pledge__form_group_email">
                    <label for="text">Phone Number</label>
                    <input
                    disabled 
                      type="text"
                      class="form-control"
                      name="number"
                 
                      value={phone}
                      onChange={getphone}
                    />
                  </div>
                  <div class="form-group form_group_email pledge__form_group_email">
                    <label for="text">Email (Optional)</label>
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                     
                      value={email}
                      onChange={getemail}
                    />
                  </div>
                </div>
              </form>
                  <div class="lrvs_comp_button">
                      <button onClick={updateprofile}>Add</button>
                  </div>
                  

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default MyPleadgeForm