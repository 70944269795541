import React, { useEffect } from 'react'
import { NavLink, json } from "react-router-dom";
import axios from 'axios';
import { useState } from 'react';
import swal from 'sweetalert';
import { useNavigate  } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import GoogleLoginComponent from './GoogleLogin';
import { GoogleOAuthProvider } from '@react-oauth/google';
import FacebokLogin from './FacebokLogin';
import { LoginSocialFacebook } from 'reactjs-social-login';
import AppleSignInButton from './AppleSignInButton ';
import jwtDecode from 'jwt-decode';



  function Login() {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://production';
    const navigate = useNavigate();
  const [phoneno, setphoneno] = useState();
  const [otpdata, setotpdata] = useState();
  const [otp, setotp] = useState();
  const [afterotp, setafterverify]=useState();
  const[forfieldotp,setsetafterverify]=useState("none");
  const[forfieldphone,setsetafterphone]=useState("flex");
  const [value, setValue] = useState()
  const [stylephonin,setstylph]=useState({textAlign: 'right',color: 'red', display: 'none'});
  const [ClientSecret,setclientsecret]=useState('')

  
  const getphoneno = ()=>{
    // console.log(phoneno)
  }
  const getotp = (event)=>{
    setotp(event.target.value)
  }
  const submitlogin =(e)=>{
  e.preventDefault();
  const formData = new FormData();
  formData.append('phone',phoneno);
  const config = { 
    headers: {'content-type': 'multipart/form-data'}
     }
  axios.post(`${API_ENDPOINT}/auth/signup-phone-send-otp`,formData,config)
  .then((res)=>{
    setotpdata(res.data.data);
    alert("OTP IS : " + res.data.data.otp);
    setsetafterverify("block");
    setsetafterphone("none");

  })
  }

   const verifyotp=(e)=>{
    e.preventDefault();
    const data = {
      "id":otpdata.user_id, "otp" : otp
    }
     const config = { 
      headers: {'Content-Type': 'application/json',}
       }
    axios.post(`${API_ENDPOINT}/auth/signup-verify-otp`,data,config)
    .then((res)=>{
     console.log(res)
      setafterverify(res.data.data);
      localStorage.setItem('Token', res.data.data.access_token);
      localStorage.setItem('Userinfo', JSON.stringify(res.data.data.user)); 
      setlanguagefordefault( res.data.data.user.lang);
      if(res.status === 200){
        swal("Success", res.data.message, "success", {
          buttons: false,
          timer: 2000,
        })
        window.location.href="/mypledge";
       // window.location.reload(true);
       }
    })
    .catch((err)=>{
      swal("Failed", err.data.message, "error", {
        buttons: false,
        timer: 2000,
      })
    })
   }
  

   const setlanguagefordefault =(langcode)=>{
    axios.get(`${API_ENDPOINT}/translation-get/${langcode}`,)
    .then((res) => {
     console.log(res)
      localStorage.setItem('app-lang',JSON.stringify(res.data.data));   
    });
   }

   const generateClientSecret = async () => {
    const keyIdentifier = 'FQFMZN6FL8';
    const teamIdentifier = 'R3XK76TKV8';
    const privateKey = `-----BEGIN PRIVATE KEY-----
    MIGTAgEAMBMGByqGSM49AgEGCCqGSM49AwEHBHkwdwIBAQQgRwilF6hsoJiZXDLC
    0ZIp6uZnoRrOCEMd+c1atCrswu6gCgYIKoZIzj0DAQehRANCAASAJGVbBF3KJoeI
    vyBUTDnwXcnyzdA4FXqZEd3lTtMMRnZXGR9Vg+Ek995qXg2ihoQjEkbcQtifrvfK
    2Gz/b96B
    -----END PRIVATE KEY-----`;
  

    const jwt = jwtDecode(); // Using a library like `jsonwebtoken`
  
    const headers = {
      alg: 'ES256',
      kid: keyIdentifier,
    };
  
    const claims = {
      iss: teamIdentifier,
      iat: Math.floor(new Date().getTime() / 1000),
      exp: Math.floor(new Date().getTime() / 1000) + 15777000, // Set the expiration time as needed
      aud: 'https://appleid.apple.com',
      sub: 'org.gieogita.18shlokarecitations',
    };
  
    const clientSecret = jwt.sign(claims, privateKey, { algorithm: 'ES256', header: headers });
    console.log('Client Secret:', clientSecret);
    setclientsecret(clientSecret);
  };
  
   

  //  const getdatafofrlogin = (data1) =>{
  //   const sendingdata =data1.data
  //      const data2 = {
  //        "name":sendingdata.first_name+" "+sendingdata.last_name,
  //       "email":sendingdata.email,
  //  "profile":sendingdata.picture.data.url,
  //  "loginType":"facebook"
  //      }
  //       const config = { 
  //        headers: {'Content-Type': 'application/json',}
  //         }
  //      axios.post(`${API_ENDPOINT}/auth/social-login`,data2,config)
  //      .then((res)=>{
  //        localStorage.setItem('Token', res.data.data.access_token);
  //        localStorage.setItem('Userinfo', JSON.stringify(res.data.data.user));
         
  //        if(res.status === 200){
  //          swal("Success", res.data.message, "success", {
  //            buttons: false,
  //            timer: 2000,
  //          })
  //          window.location.href="/mypledge";
  //          window.location.reload(true);
  //         }
  //      })
  //      .catch((err)=>{
  //        swal("Failed", err.data.message, "error", {
  //          buttons: false,
  //          timer: 2000,
  //        })
  //      })
   
  //    }
       // Function to extract code parameter from the URL
       const exchangeCodeForTokens = async (authorizationCode,) => {
        try {
          const tokenEndpoint = 'https://appleid.apple.com/oauth/token';
          const requestBody = {
            client_id: 'org.gieogita.18shlokarecitations',
            client_secret: ClientSecret,
            code: authorizationCode,
            grant_type: 'authorization_code',
            redirect_uri: 'https://18shlokarecitation.gieogita.org/login',
          };
  
          const response = await fetch(tokenEndpoint, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          });
  
          if (response.ok) {
            const tokenData = await response.json();
            console.log('Tokens:', tokenData);
            // Handle tokens here
          } else {
            throw new Error('Token exchange failed.');
          }
        } catch (error) {
          console.error('Error exchanging code for tokens:', error);
          // Handle errors here
        }
      };


    const getCodeFromURL = () => {
      const url = window.location.href;
      const urlObject = new URL(url);
      const code = urlObject.searchParams.get('code');
      console.log(code)
      const authorizationCode = code;
    exchangeCodeForTokens(authorizationCode);
    };


     useEffect(()=>{
       // Function to exchange authorization code for tokens
       generateClientSecret();
       getCodeFromURL();
   

  
     },[])
  return (
  
     <section class="login_sec">
     <section class="width___fix">
      <div class="container">
        <div class="row">
          <div class="col-md-8 m-auto">
            <div class="login_card">
              <div class="lc_image">
                <img src="/images/logo_gita.png" alt=""  />
              </div>
              <div class="lc_form">
                <h2>Login to your account</h2>
                <form>
                  <div class="form_input">
                    <div class="form-group form_group_email">
                    <PhoneInput
                     class="form-control"
      placeholder="Phone NO"
      defaultCountry="IN"
      value={phoneno}
      style={{display: forfieldphone}}
      onChange={setphoneno}/>
      <p style={stylephonin} class="mt-2">please enter valid phone no</p>

                       <input
                        type="phone"
                        class="form-control"
                       
                        placeholder="OTP"
                        style={{display: forfieldotp}}
                        onChange={getotp}
                      />
                    </div>
                    <div class="form-group form_group_fgbtn">
                        <a href="#">Forgot Password?</a>
                    </div>
                    <div class="form-group form_group_btn">
                        <button style={{display: forfieldphone}} onClick={submitlogin}><p>Login</p>
                            <span class="right__icon"><i class="fas fa-arrow-right"></i></span></button>
                        
                    </div>
                     <br/>
                    <div class="form-group form_group_btn">
                        <button style={{display: forfieldotp}}  onClick={verifyotp}><p>Verify OTP</p>
                            <span class="right__icon"><i class="fas fa-arrow-right"></i></span></button>
                        
                    </div>
                  </div>
                </form>
                <h3 class="lc_or">OR</h3>
                <div class="lc__icon_box">
                    <div class="lc_img_icon_txt">
                       <p>Login with</p>
                    </div>
                    <div class="lc_img_icon_cont_box">
                    <GoogleOAuthProvider clientId="219115132027-5ij8s4aeetb6t9vud67u34bqu0dhvvgs.apps.googleusercontent.com">
                    <GoogleLoginComponent/>
                    </GoogleOAuthProvider>

                    <AppleSignInButton/>
                        {/* <div class="lc_img_icon_cont" >
                          <LoginSocialFacebook
                          appId='681644770479642'
                          onResolve={(resp)=>{
                            getdatafofrlogin(resp)
                          }}
                          onReject={(err)=>{
                            console.log(err);
                          }}
                          >
                          <FacebokLogin/>
                          </LoginSocialFacebook>
                          
                        </div> */}
                       
                        {/* <div class="lc_img_icon_cont">
                            <a href="#"><img src="/images/linkedin.png" alt="" /></a>
                        </div>
                        <div class="lc_img_icon_cont">
                            <a href="#"><img src="/images/twite.png" alt="" /></a>
                        </div> */}
                    </div>
                </div>
                {/* <div class="lc___ragister">
                    <p>Don't have an account <NavLink to="/create"><a  target="_blank" rel="noopener noreferrer">Register Here</a></NavLink></p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>
    </section>
  
  )
}

export default Login