import React from 'react'
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import  Moment  from 'moment';
import { NavLink } from 'react-router-dom';


function Eventsubmission(prop) {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://production';
    const token = localStorage.getItem('Token');
    const [name,setname]=useState("Loading...");
    const [chanthistory,setchanthistory]=useState([]);
    const [statstt,setstatstt] = useState('loading..');
    const [statsmonthly,setmonthly] = useState('loading..');
    const [weaklyconunt,setweaklyconunt] = useState('loading..');
    const [appcounttotal,setappcounttotal] = useState('loading..');
    const [modalimage,setimageurlmodal] = useState('');
    const paramda =useParams();
const paramis =paramda.id
  
  const getdatahistory =(prop)=>{
   
    const headers = {'Authorization': `Bearer ${token}`}
    axios.get(`${API_ENDPOINT}/events-history/${paramis}`,{headers})
    .then((resp)=>{
  console.log(resp)
      setchanthistory(resp.data.data);  
    })
    .catch((e)=>{
  console.log(e)
    })
  }
  
  const usrinfo = ()=>{
    const headers = {'Authorization': `Bearer ${token}`}
    axios.get(`${API_ENDPOINT}/profile-details`,{headers})
    .then((resp)=>{
    
      setname(resp.data.data[0].name);
    })
    .catch((e)=>{
  console.log(e)
    })
  }
  const getstats =() =>{
    const headers = {'content-type': 'multipart/form-data','Authorization': `Bearer ${token}`} 
    axios.get(`${API_ENDPOINT}/show-current-chants-count`,{headers})
    .then((resp)=>{
      
      setstatstt(resp.data.data.life_time_count);
      setappcounttotal(resp.data.data.total_app_count);
      setmonthly(resp.data.data.month_count);
      setweaklyconunt(resp.data.data.weekly_count);
    })
    .catch((err)=>{
      console.log(err)
    })
  }
  useEffect(()=>{
    usrinfo();
    getdatahistory();
    getstats();
  },[]);
  
  function  getdateformated(data){
    let dateformated = Moment(data).format('DD-MM-YYYY');
        return dateformated
    }
const setimageurl = (url)=>{
  setimageurlmodal(url);
}
    const appLang = prop.language
  const submissions_list = appLang.find(obj => obj.key === "submissions_list").value;
  const date = appLang.find(obj => obj.key === "date").value;
  const count = appLang.find(obj => obj.key === "count").value;

    return (
      <section class="event_sec">
        <div class="modal fade" id="imageperticular" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="share" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body text-center">
    
        <img src={modalimage} class='w-100'/>
      </div>
    </div>
  </div>
</div>
      <div class="container">
        <div class="row">
          <div class="col-md-5 m-auto">
            <div class="enent_card">
              <div class="enent_card__container">
                
                <div class="enent_card_chants_box">
                  <div class="enent_card_chants_box_main">
                    <div class="enent_card_chants_box_dropdown "  >
                      <div class="dropdown text-center"  >
                        <div class="select" >
                          <span style={{margin: 'auto'}}>{name}</span>
                          
                        </div>
                        {/* <!-- <input type="hidden" name="gender"> --> */}
                        <ul class="dropdown-menu">
                          <li id="user1">User 1</li>
                          <li id="user2">User 2</li>
                          <li id="user3">User 3</li>
                          <li id="user4">User 4</li>
                          <li id="user5">User 5</li>
                          <li id="user6">User 6</li>
                          <li id="user7">User 7</li>
                        </ul>
                      </div>
                        {/* <!-- <span class="msg"></span> --> */}
                    </div>
                    
                    <div class="pledge_card_chants_heda">
                      <p>{submissions_list}</p>
                  </div>
                  <div class="pledge_card_chants_table">
                    <table>
                        <tr>
                            <td>{date}</td>
                            <td>{count}</td>
                        </tr>
                        {chanthistory.map((data)=>{
                          return(
                            <tr>
                            <td>{getdateformated(data.date)} <img class="dekool" onClick={()=>setimageurl(data.photo)} data-bs-toggle="modal" data-bs-target="#imageperticular" src={data.photo}/></td>
                            <td style={{position: 'relative'}}>{data.count} <NavLink to={`/update-event/${data.id}/${data.count}`} ><img src='/images/pencil.png' class='editbtnsd'/></NavLink></td>
                        </tr>
                          )
                        })}
                       
                    </table>
                </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    )
  }

export default Eventsubmission