import Login from "./components/Pages/Login";
import About from "./components/Pages/About"
import Header from "./components/common/Header";
import Archive from "./components/Pages/Archive";
import Create from "./components/Pages/Create";
import Events from "./components/Pages/Events";
import Help from "./components/Pages/Help";
import { browserHistory } from 'react-router';
import LandingRead from "./components/Pages/LandingRead";
import MyPleadge from "./components/Pages/MyPleadge";
import MyPleadgeForm from "./components/Pages/MyPleadgeForm";
import OldCounts from "./components/Pages/OldCounts";
import Menus from "./components/Pages/Menus";
import Submission from "./components/Pages/Submission";
import { BrowserRouter, Routes,Switch,Route, HashRouter } from 'react-router-dom';
import LanguageList from "./components/Pages/LanguageList";
import Bottom from "./components/common/Bottom";
import Bottomdefault from "./components/common/Bottomdefault";
import Headerbefore from "./components/common/Headerbefore";
import PrivacyPolicy from "./components/Pages/PrivacyPolicy";
import AllEvents from "./components/Pages/AllEvents";
import Perticular from "./components/Pages/Perticular";
import Createevent from "./components/Pages/Createevent";
import Locationupdatafterevent from "./components/Pages/Locationupdatafterevent";
import Eventsubmission from "./components/Pages/Eventsubmission";
import Updateeventhistory from "./components/Pages/Updateeventhistory";
import axios from "axios";
import { useEffect } from "react";


function App() 
{
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://production';
  const token = localStorage.getItem('Token');



// useEffect(()=>{
//   if(!token){
//     console.log("check")
//     axios.get(`${API_ENDPOINT}/translation-get/en`)
//     .then((res) => {
//       console.log(res)
//       localStorage.setItem('app-lang',JSON.stringify(res.data.data));
//     })
//     .catch(err=>{console.log(err)})
//   }
// },[])
  var appLang = JSON.parse(localStorage.getItem('app-lang'));
  const handler = ()=>{
     appLang = JSON.parse(localStorage.getItem('app-lang'));
     window.location.reload()
  }
  if(!token){
    console.log("check")
    if(!appLang){
      axios.get(`${API_ENDPOINT}/translation-get/en`)
      .then((res) => {
        console.log(res)
        localStorage.setItem('app-lang',JSON.stringify(res.data.data));
        appLang = JSON.parse(localStorage.getItem('app-lang'));
        window.location.reload();
      })
      .catch(err=>{console.log(err)})
    }
  
    return(
      <BrowserRouter>
      <Headerbefore/>
      <Routes>
      <Route path='/'Component={()=><About language={appLang} />} />
      <Route path='/login' exact Component={()=><Login language={appLang} />} />
      <Route path='/create' exact Component={()=><Create language={appLang} />} />
      <Route path='/languageList' exact Component={()=><LanguageList  handler={handler} language={appLang} />} />
      <Route path='/privacy-policy' exact Component={()=><PrivacyPolicy language={appLang} />} />
      <Route path="*" element={<>Not Found !!!!!!!!!!!!!!!!!!!!  Bruh</>} />
      </Routes>
      <Bottomdefault/>
      </BrowserRouter>
    )
  }
  // else if(!token || token){
  //   <BrowserRouter>
  //   <Headerbefore/>
  //   <Routes>
  //   <Route path='/'Component={()=>About}/>
  //   <Route path='/languageList' exact Component={()=>LanguageList} />
  //   <Route path='/privacy-policy' exact Component={()=>PrivacyPolicy} />
  //   <Route path="*" element={<>Not Found !!!!!!!!!!!!!!!!!!!!  Bruh< language={appLang} />} />

  //   </Routes>
  //   <Bottomdefault/>
  //   </BrowserRouter>
  // }
  else{
    return (
      <>
      <BrowserRouter >
      <Header language={appLang}/>
      <Routes>
        <Route path='/'Component={()=><About language={appLang} />} />
        <Route path='/archive'  Component={()=><Archive language={appLang} />} />
        <Route path='/menus'  Component={()=><Menus language={appLang} />} />
        <Route path='/create' exact Component={()=><Create language={appLang} />} />
        <Route path='/languageList'  exact Component={()=><LanguageList handler={handler}  language={appLang} />} />
        <Route path='/events/:id' exact Component={()=><Events language={appLang} />} />
        <Route path='/update-event/:id/:count' exact Component={()=><Updateeventhistory language={appLang} />} />
        <Route path='/events-history/:id' exact Component={()=><Eventsubmission language={appLang} />} />
        <Route path='/help' exact Component={()=><Help language={appLang} />} />
        <Route path='/landingRead' exact Component={()=><LandingRead language={appLang} />} />
        <Route path='/mypledge' exact Component={()=><MyPleadge language={appLang} />} />
        <Route path='/mypledgeForm' exact Component={()=><MyPleadgeForm language={appLang} />} />
        <Route path='/oldCounts' exact Component={()=><OldCounts language={appLang} />} />
        <Route path='/submission' exact Component={()=><Submission language={appLang} />} />
        <Route path='/all-events' exact Component={()=><AllEvents language={appLang} />} />
        <Route path='/eventdetails/:id' exact Component={()=><Perticular language={appLang} />} />
        <Route path='/create-event/:id' exact Component={()=><Createevent language={appLang} />} />
        <Route path='/privacy-policy' exact Component={()=><PrivacyPolicy language={appLang} />} />
        <Route path='/locationadd/:id' exact Component={()=><Locationupdatafterevent language={appLang} />} />
        <Route path="*" element={<>Not Found !!!!!!!!!!!!!!!!!!!!  Bruh</>}/>
      </Routes> 
      <Bottom/> 
      </BrowserRouter >
      </>
    );
  }

  
}

export default App;
