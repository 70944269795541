import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import axios from 'axios';


function Header(prop) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://production';
  const token = localStorage.getItem('Token');
  const [imgpro,setimgpro]=useState(null);
  const [firstl,setfirstl]=useState(null);

  const profileview = () =>{
    const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${token}` }
    axios.get(`${API_ENDPOINT}/profile-details`, { headers })
      .then((res) => {

        console.log(res)
        if(res.data.data[0].avatar_original ==null){
          const fl = res.data.data[0].name.split('')
          setfirstl(fl[0]);
        }
        else{
          setimgpro(res.data.data[0].avatar_original);
        }
       
      })
      .catch((err) => {
        console.log(err)
      })
  }
useEffect(()=>{
  profileview();
},[])

  const appLang = prop.language
 const gieo_geta = appLang.find(obj => obj.key === "geeta_jeevan_geet").value;
 const eighteen_verse_gita_recitation_campaign = appLang.find(obj => obj.key === "eighteen_verse_gita_recitation_campaign").value;
  return (
    <header>
      <div className="nav_menu_main">
        <div className="nmm_logo">
        <NavLink to="/"><img src="/images/logo_gita.png" alt="" /></NavLink> 
        </div>
        <div className="nmm_header text-center">
          <h3><span >{gieo_geta}</span></h3>
          <p><b>{eighteen_verse_gita_recitation_campaign}</b></p>
        </div>
        <NavLink to="/menus">
        <div className="nmm_user">
          {firstl === null?(<><img src={imgpro}/></>):(<p>{firstl}</p>)}
        </div>
        </NavLink>
      </div>
    </header>
  )
}

export default Header