import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import swal from 'sweetalert';

function GoogleLoginComponent() {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://production';
 const getdatafofrlogin = (data1) =>{
  console.log(data1)
 const sendingdata =data1
    const data = {
      "name":sendingdata.given_name+" "+sendingdata.family_name,
"email":sendingdata.email,
"profile":sendingdata.picture,
"loginType":"google"
    }
     const config = { 
      headers: {'Content-Type': 'application/json',}
       }
    axios.post(`${API_ENDPOINT}/auth/social-login`,data,config)
    .then((res)=>{
      localStorage.setItem('Token', res.data.data.access_token);
      localStorage.setItem('Userinfo', JSON.stringify(res.data.data.user));
      localStorage.setItem('socialprofileimg', JSON.stringify(sendingdata.picture)); 
      
      if(res.status === 200){
        swal("Success", res.data.message, "success", {
          buttons: false,
          timer: 2000,
        })
        window.location.href="/mypledge";
        //window.location.reload(true);
       }
    })
    .catch((err)=>{
      swal("Failed", err.data.message, "error", {
        buttons: false,
        timer: 2000,
      })
    })
  }



  const verifyotp=(e)=>{
    
   }
  return (
    <>
    <GoogleLogin
    onSuccess={credentialResponse => {
        let creddential = credentialResponse.credential
        const result= jwtDecode(creddential)
      getdatafofrlogin(result)
    }}
    onError={() => {
      console.log('Login Failed');
    }}
  />
    </>
   
  )
}

export default GoogleLoginComponent


