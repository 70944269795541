import axios from 'axios';
import React, { useEffect, useState } from 'react'
import swal from 'sweetalert';
import  Moment  from 'moment';


const token = localStorage.getItem('Token');
const userid = localStorage.getItem('User');

function MyPleadge(prop) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://production';
const [mytargetchant,setmytargetchant] = useState(0);
const [setinputalue,setchant] = useState();
const [Dailyy,setDaily]=useState(0)
const [Weeklyy,setWeekly]=useState(0)
const [Monthlyy,setMonthly]=useState(0)
const [Total,setTotal]=useState(0)


const appLang = prop.language
const my_pledge_description = appLang.find(obj => obj.key === "my_pledge_description").value;
const my_pledge	 = appLang.find(obj => obj.key === "my_pledge").value;
const ashtadash_shloki_geeta_path = appLang.find(obj => obj.key === "ashtadash_shloki_geeta_path").value;
const fixed_number	 = appLang.find(obj => obj.key === "fixed_number").value;
const daily	 = appLang.find(obj => obj.key === "daily").value;
const weekly	 = appLang.find(obj => obj.key === "weekly").value;
const monthly	 = appLang.find(obj => obj.key === "monthly").value;
const total	 = appLang.find(obj => obj.key === "total").value;
const submit	 = appLang.find(obj => obj.key === "submit").value;
const resolve_to	 = appLang.find(obj => obj.key === "resolve_to").value;
const note_description	 = appLang.find(obj => obj.key === "note_description").value;
var by_geeta_jayanti	 = appLang.find(obj => obj.key === "by_geeta_jayanti").value;

by_geeta_jayanti=by_geeta_jayanti.replace('{target_date}','23 Dec 2023')
const note	 = appLang.find(obj => obj.key === "note").value;

var temp=0;
const getvalues = ()=>{
  
   const headers = {'content-type': 'multipart/form-data','Authorization': `Bearer ${token}`}  
  //  const userdefaultlang
      
  axios.get(`${API_ENDPOINT}/profile-details`,{headers})
  .then((res)=>{
   console.log(res)
    setchant(res.data.data[0].target_count);
    const today = new Date(); 
    const tilldate = new Date('12/23/2023');
    let todayformated = Moment(today).format('MM/DD/YYYY');
    const startdate = new Date(todayformated);
    var diff = tilldate.getTime() - startdate.getTime();   
    console.log(typeof(diff))
    var daydiff = diff / (1000 * 60 * 60 * 24);  
    var perday = res.data.data[0].target_count/daydiff;
    var permonth = perday*30;
    var perweek = perday*7; 
    if(perday <1){
      perday=1;
    }
    
    setDaily(perday/1);
    setMonthly(permonth/1);
    setWeekly(perweek/1);
    setTotal(res.data.data[0].target_count/1);
  
  })
}

const updatevalue = ()=>{
   const formData = new FormData();
  formData.append('count',setinputalue);
  const headers = {'content-type': 'multipart/form-data','Authorization': `Bearer ${token}`}   
  axios.post(`${API_ENDPOINT}/change-traget-count`,formData,{headers})
  .then((res)=>{
 
   if(res.status === 200){
    swal("Success", res.data.message, "success", {
      buttons: false,
      timer: 2000,
    })
   }
   getvalues();
  })
  .catch((err)=>{
    swal("Failed", err.data.message, "error", {
      buttons: false,
      timer: 2000,
    })
  })
}
useEffect(()=>{
  getvalues();  
  
},[])
const getinputvalue = (e)=>{
  setchant(e.target.value);
  const today = new Date(); 
  const tilldate = new Date('12/23/2023');
  let todayformated = Moment(today).format('MM/DD/YYYY');
  const startdate = new Date(todayformated);
  var diff = tilldate.getTime() - startdate.getTime();   
  console.log(typeof(diff))
  var daydiff = diff / (1000 * 60 * 60 * 24);  

 
  var perday = e.target.value/daydiff;
  var permonth = perday*30;
  var perweek = perday*7; 

  if(perday <1){
    perday=1;
  }

  setDaily(perday);
  setMonthly(permonth);
  setWeekly(perweek);
  setTotal(e.target.value/1);
}

  return (
    <section class="event_sec">
    <div class="container">
      <div class="row">
        <div class="col-md-5 m-auto">
          <div class="enent_card">
            <div class="enent_card__container">
              <div class="enent_card_chants_box">
                <div class="enent_card_chants_box_main">
                  <div class="enent_card_chants_box_main_head">
                      <h3>{my_pledge}</h3>
                  </div>

                  <div class="pledge_card_chants_box_main_sub_head">
                      <p>{my_pledge_description}</p>
                  </div>
                  <div class="pledge_card_chants_heda">
                      <p>{resolve_to}</p>
                  </div>
                  <div class="enent_card_chants_box_main_sub_dis_count">
                    <form>
                    <input type="number" value={setinputalue} min={0} onChange={getinputvalue}/>
                    </form>
                  </div>
                  <div class="pledge_card_chants_heda_title">
                      <p>{ashtadash_shloki_geeta_path}</p>
                  </div>
                  <div class="pledge_card_chants_box_main_sub_head">
                      <p>{by_geeta_jayanti}</p>
                  </div>
                  <div class="pledge_card_chants_heda">
                      <p>{fixed_number}</p>
                  </div>
                  <div class="pledge_card_chants_table">
                      <table>
                      <thead>
                        </thead>
                          <tr>
                              <td>{daily}</td>
                              <td>{parseInt(Dailyy)}</td>
                          </tr>
                          <tr>
                              <td>{weekly}</td>
                              <td>{parseInt(Weeklyy)}</td>
                          </tr>
                          <tr>
                              <td>{monthly}</td>
                              <td>{parseInt(Monthlyy)}</td>
                          </tr>
                          <tr>
                              <td>{total}</td>
                              <td>{Total}</td>
                          </tr>
                      </table>
                  </div>
                  <div class="lrvs_comp_button">
                      <button onClick={updatevalue}>{submit}</button>
                  </div>
                  <div class="lver_term__con">
                     {note}:{note_description}
                  </div>
                  {/* <div class="lver_term__con__btn">
                      <a href="#">Continue without taking any pledge</a>
                  </div> */}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  )
}

export default MyPleadge